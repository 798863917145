<template>
  <div class="page host-settlement-amount">
    <div class="page-header">
      <h1 class="page-header__title">정산 금액 확인</h1>
    </div>

    <div class="page-content">
      <ul class="section">
        <li class="duration">
          <div class="title">기간</div>
          <div>{{ data.date_start }} ~ {{ data.date_end }}</div>
        </li>
        <li class="usage-info">
          <div class="title">이용 정보</div>
          <div class="detail">
            <!-- <div class="count">{{ data?.receipt_list?.length }}건</div> -->
            <div class="count">{{ data?.receipt_list?.length - cancel }}건</div>
            <button @click="onClickShowDetail(data)" class="button">상세 확인</button>
          </div>
        </li>
        <li>
          <div>총 발생 금액(A)</div>
          <div>{{ profit }} {{ $__t('won') }}</div>
        </li>
        <li class="fee">
          <div>이용료 및 결제 수수료(B)</div>
          <div>- {{ fee }} {{ $__t('won') }}</div>
        </li>
        <li class="total">
          <div>호스트 수익(A-B)</div>
          <div class="host_profit">{{ total }} {{ $__t('won') }}</div>
        </li>
      </ul>
      <button @click="onClickRequestSettlement" class="request" :class="{ inactivated: !availableSettlementRequest }">정산 신청하기</button>
      <div class="notice">
        <div class="title">💰💰정산 신청 안내💰💰</div>
        <ul>
          <li>1. 정산 신청 기간은 매달 5일~10일입니다.</li>
          <li>2. 정산 신청 기간이 아닐 경우 지난달까지의 정산 금액 확인만 가능합니다.</li>
          <li>3. 신청하지 않은 정산금은 첫 발생 월로부터 1년까지 누적하여 신청 가능합니다.</li>
          <li>4. 정산금 지급은 신청 기간 마지막 날로부터 영업일 기준 5일 이내 완료 됩니다.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HostSettlementAmount',
  components: {},
  computed: {
    storeId() {
      return this.$route.query.store_id;
    },
    profit() {
      // return (this.data.total_origin || 0).toLocaleString();
      let total = 0;
      this.$data.data?.items?.forEach((profit) => {
        total = total + profit.total_origin;
      });
      return total.toLocaleString('ko-KR');
    },

    fee() {
      // return (this.data.total_fee || 0).toLocaleString();
      let totalFee = 0;
      this.$data.data?.items?.forEach((item) => {
        totalFee = totalFee + item.total_fee;
      });
      return totalFee.toLocaleString('ko-KR');
    },
    total() {
      // return (this.data.settled_total || 0).toLocaleString();
      // 여기서 수정
      let hostProfit = 0;
      this.$data.data?.items?.forEach((profit) => {
        hostProfit = hostProfit + profit.total_profit;
      });
      return hostProfit.toLocaleString('ko-KR');
    },
    docs() {
      return this.$store.getters['hostSettlements/GET_ITEMS_BY_LIST']({
        list: 'MyStoreSettlements_all'
      });
    },
    settlementRequestAvailable() {
      return this.docs.length === 0;
    },
    cancel() {
      let count = 0;

      for (let i = 0; i < this.$data.data?.receipt_list?.length; i++) {
        if (this.$data.data.receipt_list[i].ticket_status === 'CANCELLED') {
          count++;
        }
      }
      return count;
    },
    availableSettlementRequest() {
      if (this.$store.state.config.mode !== 'production') {
        return true;
      }
      
      return Number(this.$data?.data?.current_day) >= 5 && Number(this.$data?.data?.current_day) <= 10;
    }
  },
  watch: {},
  methods: {
    async fetchDashboard() {
      try {
        this.$store.commit('loading/SET_TRUE');
        this.$data.data = await this.$store.dispatch('hostSettlements/dashboard', {
          settled_method: 'TAX_BILL', //세금계산서 형식
          store_id: '' //전체일 경우에는 store_id가 넘어가지 않기 때문에 빈문자열로 보냄.
        });
      } catch (e) {
      } finally {
        this.$store.commit('loading/SET_FALSE');
      }
    },
    //  async fetchBankAccount() {
    //   try {
    //     this.$store.commit('loading/SET_TRUE');
    //     this.$data.data = await this.$store.dispatch('bankAccounts/fetchList', {
    //       page: 1,
    //       size: 100,
    //       list: 'MyBankAccounts'
    //     });
    //   } catch (e) {
    //   } finally {
    //     this.$store.commit('loading/SET_FALSE');
    //   }
    // },
    onClickRequestSettlement() {
      this.$store.commit('loading/SET_TRUE');
      setTimeout(() => {
        this.$store.commit('loading/SET_FALSE');
        this.$router.replace({
          name: 'HostSettlementRequest',
          query: { store_id: this.storeId }
        });
      }, 500);
    },
    onClickShowDetail({ receipt_list }) {
      this.$router.push({
        name: 'ListsForHostSettlement',
        params: { lists: receipt_list }
      });
    }
  },
  data() {
    return {
      data: {}
    };
  },
  created() {
    this.fetchDashboard();
    // this.fetchBankAccount();
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.page.host-settlement-amount {
  .page-header {
    padding: unit(24) unit(16);

    &__title {
      font-weight: bold;
      font-size: unit(22);
    }
  }
  .page-content {
    padding: unit(20);
    .section {
      font-size: unit(14);
      li {
        padding: unit(10) 0;
        height: unit(50);
        display: flex;
        justify-content: space-between;
        &.usage-info {
          margin-bottom: unit(5);
        }
        &.fee {
          margin-bottom: unit(20);
        }
        &.total {
          border-top: 1px solid #e1e4e6;
        }
        .detail {
          display: flex;
          justify-content: space-between;
          gap: unit(5);
          button {
            color: white;
            font-size: unit(12);
            background-color: $color-primary-9;
            border-radius: 8px;
            border: solid 1px #ced3d6;
            width: unit(70);
            height: unit(24);
          }
        }
        .host_profit {
          font-weight: 700;
          font-size: unit(16);
        }
      }
      .title {
        font-size: unit(16);
        font-weight: 700;
      }
    }
    .request {
      font-size: unit(14);
      background-color: $color-primary-9;
      height: unit(50);
      border-radius: 8px;
      color: $color-white;
      margin-top: unit(20);
      &.inactivated {
        background-color: #ced3d6;
        pointer-events: none;
      }
    }
    .notice {
      margin-top: unit(20);
      padding: unit(14);
      border: 1px solid #ced3d6;
      line-height: unit(20);
      .title {
        text-align: center;
        font-size: unit(16);
        font-weight: 700;
        margin-bottom: unit(10);
      }
      ul {
        font-size: unit(14);
        li {
          padding-left: unit(5);
          text-indent: unit(-13);
        }
      }
    }
  }
}
</style>
